import {MenuProps, Modal, Tooltip} from 'antd';
import {Breadcrumb, Layout, Menu} from 'antd';
import React, {useEffect, useState} from 'react';
import './index.css';
import {Outlet, useNavigate, useLocation} from "react-router-dom";
import NotificationDropDown from "../../components/notificationDropDown";
import AvatarDropDown from "../../components/avatarDropDown";
import mapIcon from "../../assets/icons/map.svg"
import {showFail} from '../../components/functions';
import {getMyAppList} from "../../api";


const App: React.FC = () => {
    const [headerMenuItems, setHeaderMenuItems] = useState<any[]>([]);
    const [items, setItems] = useState<MenuItem[]>([]);
    const [baseManageItems,setBaseManageItems] = useState< MenuItem[]>( []);
    const [commandItems,setCommandItems] =useState< MenuItem[]>( []);
    const [serviceItems,setServiceItems] =useState< MenuItem[]>( []);
    const handleGetMyAppList = async () => {
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
        const isCommandManager = JSON.parse(window.localStorage?.getItem("isCommandManager") as string);
        const isServiceManager = JSON.parse(window.localStorage?.getItem("isServiceManager") as string);
        const isAreaManager = JSON.parse(window.localStorage?.getItem("isAreaManager") as string);
        getMyAppList().then((res: any) => {
            let data = res?.data?.map((d: any) => {
                return {
                    key: d?.value,
                    label: d?.description
                }
            });
            if (isSuperManager||isCommandManager||isServiceManager||isAreaManager) {
                data.push(
                    {key: '3', label: "基础管理"},
                )
            }

            if(data?.length===0){
                Modal.warning({
                    title: '错误提示',
                    content: '您没有配置任何应用',
                    onOk:()=>{
                        window.localStorage.clear();
                        window.location.href = '/#/login';
                    }
                });
            }else{
                let obj: any;
                let _baseManageItems: MenuItem[] = [];
                let _commandItems: MenuItem[] = [];
                let _serviceItems: MenuItem[] = [];

                let authentications = window.localStorage.getItem('authentications');
                if (!authentications) {
                    showFail("登录已失效, 请重新登录", () => {
                        window.location.href = "/#/login"
                        window.location?.reload();
                    })
                } else {
                    obj = JSON.parse(window.localStorage.getItem('authentications')!.toString()).map((e: any) => {
                        return (e.authority)
                    })

                    if (obj.indexOf("基础管理") != -1) {
                        let a: any = [];
                        let a_s: any = [];
                        let a_m: any = [];
                        if (obj.indexOf("人员管理") != -1) {
                            // a.push(getItem('人员管理', '/manage/user'));
                            // a_s.push(getItem('人员管理', '/manage/user'));
                            a_m.push(getItem('人员管理', '/manage/user'));
                        }
                        if (obj.indexOf("角色管理") != -1) {
                            // a.push(getItem('角色管理', '/manage/role'));
                            // a_s.push(getItem('角色管理', '/manage/role'));
                            a_m.push(getItem('角色管理', '/manage/role'));
                        }
                        if (obj.indexOf("权限管理") != -1) {
                            // a.push(getItem('权限管理', '/manage/menu'));
                            // a_s.push(getItem('权限管理', '/manage/menu'));
                            a_m.push(getItem('权限管理', '/manage/menu'));
                        }
                        if (obj.indexOf("部门管理") != -1) {
                            // a.push(getItem('部门管理', '/manage/dept'));
                            // a_s.push(getItem('部门管理', '/manage/dept'));
                            a_m.push(getItem('部门管理', '/manage/dept'));

                        }
                        if (obj.indexOf("区域管理") != -1) {
                            // a.push(getItem('区域管理', '/manage/area'));
                            // a_s.push(getItem('区域管理', '/manage/area'));
                            a_m.push(getItem('区域管理', '/manage/area'));
                        }

                        if (obj.indexOf("配置管理") != -1) {
                            // a.push(getItem('配置管理', '/manage/dict'))
                            // a_s.push(getItem('配置管理', '/manage/dict'))
                            a_m.push(getItem('配置管理', '/manage/dict'))
                        }

                        if (obj.indexOf("应用管理") != -1) {
                            // a.push(getItem('配置管理', '/manage/dict'))
                            // a_s.push(getItem('配置管理', '/manage/dict'))
                            a_m.push(getItem('应用管理', '/manage/app'))
                        }


                        // a.push( getItem('配件管理', '/xc/manage/accessory_manage'))
                        // serviceItems.push(getItem('管理', 'manage', undefined, a_s));
                        _baseManageItems.push(getItem('管理', 'manage', undefined, a_m));
                        setBaseManageItems(_baseManageItems)
                    }

                    if (obj.indexOf("先锋作战指挥系统") != -1) {


                        if (obj.indexOf("管理") != -1) {
                            let a: any[] = [];
                            if (obj.indexOf("道路管理") != -1) {
                                a.push(getItem('道路管理', '/manage/road'));
                            }
                            if (obj.indexOf("XHS管理") != -1) {
                                a.push(getItem('XHS管理', '/manage/hydrant'))
                            }
                            _commandItems.push(getItem('管理', 'manage', undefined, a));
                        }


                        if (obj.indexOf("作战卡") != -1) {
                            // a.push(getItem('社区&企业管理', '/manage/communityComponent'))
                            let a1: any = []
                            a1.push(getItem('社区', '/manage/community'))
                            a1.push(getItem('重点企业', '/manage/key_company'))
                            a1.push(getItem('特殊场所', '/manage/special_place'))
                            a1.push(getItem('危化品', '/manage/chemicals'))
                            _commandItems.push(getItem('作战卡', '/manage/communityComponent', undefined, a1))
                        }


                        if (obj.indexOf("巡检") != -1) {
                            let b: any = []
                            if (obj.indexOf("巡检任务") != -1) {
                                b.push(getItem('巡检任务', '/manage/inspect_task'))
                            }
                            if (obj.indexOf("定期计划") != -1) {
                                b.push(getItem('定期计划', '/manage/inspect_task/regular'))
                            }
                            _commandItems.push(getItem('巡检', 'inspect_task', undefined, b))
                        }

                        if (obj.indexOf("防消联勤") != -1) {
                            let c: any = []
                            if (obj.indexOf("隐患排查") != -1) {
                                c.push(getItem('隐患排查', '/manage/hiddenDanger'))
                            }
                            if (obj.indexOf("宣传培训") != -1) {
                                c.push(getItem('宣传培训', '/manage/exercise'))
                            }
                            if (obj.indexOf("火灾记录") != -1) {
                                c.push(getItem('火灾记录', '/manage/fireRecord'))
                            }

                            _commandItems.push(getItem('防消联勤', '/exercise', undefined, c))
                        }

                        if (obj.indexOf("投诉与建议") != -1) {
                            _commandItems.push(getItem('投诉与建议', '/manage/suggest_complaint'))
                        }

                        if (obj.indexOf("地图") != -1) {
                            _commandItems.push(getItem('地图', '/map'))
                        }
                        setCommandItems(_commandItems)
                    }


                    if (obj.indexOf("社会消防服务") != -1) {

                        if (obj.indexOf("预约记录") != -1) {
                            _serviceItems.push(getItem('预约记录', '/manage/appointment/record'))
                        }

                        if (obj.indexOf("防消联勤(社会消防服务)") != -1) {
                            _serviceItems.push(getItem('防消联勤', '/manage/appointment/hiddenDanger'))
                        }

                        if (obj.indexOf("预约单位管理") != -1) {
                            _serviceItems.push(getItem('预约单位管理', '/manage/appointment/company'))
                        }

                        if (obj.indexOf("考试管理") != -1) {
                            _serviceItems.push(getItem('考试管理', '/manage/appointment/class'))
                        }

                        if (obj.indexOf("试题管理") != -1) {
                            _serviceItems.push(getItem('试题管理', '/manage/appointment/question'))
                        }

                        if (obj.indexOf("成绩管理") != -1) {
                            _serviceItems.push(getItem('成绩管理', '/manage/appointment/score'))
                        }

                        if (obj.indexOf("考试统计") != -1) {
                            _serviceItems.push(getItem('考试统计', '/manage/appointment/examStatics'))
                        }

                        setServiceItems(_serviceItems);
                    }
                }

                let index_service = data?.findIndex((d: any) => d.key === '1');
                if (index_service !== -1) {
                    setItems(_serviceItems);
                }

                let index_command = data?.findIndex((d: any) => d.key === '2');
                if (index_command !== -1) {
                    setItems(_commandItems);
                }

                setHeaderMenuItems(data)
            }

        }).catch((err: any) => {
            showFail(err);
            console.log('err', err)
        })
    }


    const {Header, Content, Footer, Sider} = Layout;

    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
        } as MenuItem;
    }


    useEffect(() => {
        handleGetMyAppList()
    }, [])


    const location = useLocation();
    const {pathname} = location;
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const onClick: MenuProps['onClick'] = e => {
        navigate(e.key, {replace: false})
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header style={{display: 'flex', alignItems: 'center'}} className="site-layout-header">
                <div style={{width: 150, color: '#fff', fontSize: 25}}>管理后台</div>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['command']}
                    items={headerMenuItems}
                    style={{flex: 1, minWidth: 0}}
                    onSelect={(e: any) => {
                        console.log('e', e);
                        if (e?.key === '1') {
                            setItems(commandItems)
                        } else if (e?.key === '2') {
                            setItems(serviceItems)
                        } else if (e?.key === '3') {
                            setItems(baseManageItems)
                        }
                    }}
                />

                {/*<div className="notification">*/}

                {/*    <Tooltip title='查看地图'>*/}
                {/*        <img src={mapIcon} alt="" width={25} height={25} onClick={() => {*/}
                {/*            navigate("/map")*/}
                {/*        }}/>*/}
                {/*    </Tooltip>*/}
                {/*</div>*/}

                <div className="Avatar">
                    <AvatarDropDown/>
                </div>
            </Header>

            <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
                    <div className="logo"/>
                    <Menu theme="dark" selectedKeys={[pathname]} mode="inline" items={items} onClick={onClick}/>
                </Sider>
                <Layout className="site-layout">
                    {/*<Header className="site-layout-header" style={{padding: 0}}>*/}

                    {/*    <div className="notification">*/}

                    {/*        <Tooltip title='查看地图'>*/}
                    {/*            <img src={mapIcon} alt="" width={25} height={25} onClick={() => {*/}
                    {/*                navigate("/map")*/}
                    {/*            }}/>*/}
                    {/*        </Tooltip>*/}
                    {/*    </div>*/}

                    {/*    /!*<div className="notification">*!/*/}
                    {/*    /!*    <NotificationDropDown/>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className="Avatar">*/}
                    {/*        <AvatarDropDown/>*/}
                    {/*    </div>*/}
                    {/*</Header>*/}
                    <Content style={{margin: '0 16px'}}>
                        <Breadcrumb style={{margin: '16px 0'}}/>
                        <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                            <Outlet/>
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>智慧XF管理系统 ©2024 备案号:浙ICP备2023049326号-2
                        <a style={{marginLeft: '20px', color: '#287ec0', cursor: 'pointer'}}
                           href={'https://beian.miit.gov.cn/'}>工信部备案查询</a>
                    </Footer>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default App;