import React, {useRef, useState} from 'react';
import {Button, Card, Input, Modal, Typography, Form, message} from 'antd';
import './index.css';
import {SendOutlined, EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {sendSms, userlogin} from "../../api";
import {showFail, showNetError, showSuccess, showWarning} from "../../components/functions";

const {Title, Text} = Typography;
const info = () => {
    Modal.info({
        content: '账号登陆问题请联系管理员:18905720011'
    });
};

function Login() {
    let ref: any = useRef(null);
    const [flag, setFlag] = useState(false); // 组件卸载标识
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const onFinish = (values: any) => {
        setLoading(true);
        // console.log(values);
        userlogin(values).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                // console.log(res);
                window.localStorage.setItem("token", res?.data?.token);
                window.localStorage.setItem("authentications", JSON.stringify(res?.data?.authentications));
                window.localStorage.setItem("sysUser", JSON.stringify(res?.data?.sysUser));
                let roles = res?.data?.authentications?.map((a: any) => {
                    return a?.authority
                });

                if(roles.length===1&&roles.some((o: any) => o === "ROLE_普通用户")){
                    showFail("无权限登录!")
                }else{
                    let dispatchRoleObj = 'ROLE_区域管理员'
                    let isAreaManager = roles.some((o: any) => o === dispatchRoleObj);
                    window.localStorage.setItem("isAreaManager", isAreaManager);
                    let maintenanceRoleObj = 'ROLE_巡检员'
                    let isInspector = roles.some((o: any) => o === maintenanceRoleObj);
                    window.localStorage.setItem("isInspector", isInspector);
                    let superManagerRoleObj = 'ROLE_超级系统管理员'
                    let isSuperManager = roles.some((o: any) => o === superManagerRoleObj);
                    window.localStorage.setItem("isSuperManager", isSuperManager);
                    let commandManagerRoleObj = 'ROLE_先锋作战指挥管理员'
                    let isCommandManager = roles.some((o: any) => o === commandManagerRoleObj);
                    window.localStorage.setItem("isCommandManager", isCommandManager);
                    let serviceManagerRoleObj = 'ROLE_社会消防服务管理员'
                    let isServiceManager = roles.some((o: any) => o === serviceManagerRoleObj);
                    window.localStorage.setItem("isServiceManager", isServiceManager);
                    navigate('/home');
                }

            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            showNetError();
            console.log(err)
        })
    };


    // 验证码发送倒计时
    const [yzTime, setYzTime] = useState<number>(59); // 验证码倒计时
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
    const [btnContent, setBtnContent] = useState<string>('发送');
    let time = yzTime;
    let timeChange: NodeJS.Timer;
    const clock = () => {
        if (!flag) {
            if (time > 0) {
                time -= 1;
                setYzTime(time);
                setBtnDisabled(true);
                setBtnContent(`${time}s`);
            } else {
                clearInterval(timeChange);
                setYzTime(59);
                setBtnDisabled(false);
                setBtnContent('发送');
            }
        } else {
            clearInterval(timeChange); // 组件卸载清除定时器
        }
    };

    // 发送验证码
    const sendCode = () => {
        let params = {
            mobile: ref?.current?.input?.value
        }
        if (!params?.mobile) {
            showFail("请输入手机号")
        } else {
            sendSms(params).then((res: any) => {
                if (res?.code === 200) {
                    showSuccess(res?.msg);
                    setYzTime(59);
                    setBtnDisabled(true);
                    setBtnContent('59s');
                    timeChange = setInterval(clock, 1000);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
                showNetError();
            })
        }
    };

    return (
        <div className="Login">
            <div className="LoginCard">
                <Card className="loginCardMain">
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <div className="Title">
                            <Title style={{color: "#031036", fontSize: '1.7vmax'}}>
                                智慧XF管理系统
                            </Title>
                        </div>
                        <div className="Phone">
                            <Text className='phoneText'>
                                手机号
                            </Text>
                            <Form.Item
                                className="phoneItem"
                                name="username"
                                rules={[{required: true, message: '手机号不能为空!'},
                                    {
                                        pattern: new RegExp(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/),
                                        message: '请输入正确的手机号!'
                                    }]}
                            >
                                <Input id="phone" className="phoneInput" placeholder="请输入手机号" allowClear ref={ref}/>
                            </Form.Item>
                        </div>
                        <br/>
                        <div className="PassWord">
                            <div className="PasswordText">
                                验证码
                            </div>
                            <div className="PasswordMain">
                                <div className="PasswordInput">
                                    <Form.Item
                                        className="PasswordItem"
                                        name="code"
                                        rules={[{required: true, message: '验证码不能为空!'},
                                            {
                                                pattern: new RegExp(/^\d{1,10}$/),
                                                message: '请输入正确的验证码!'
                                            }]}
                                    >
                                        <Input.Password
                                            id="password"
                                            style={{width: '10vw', height: '5vh'}}
                                            placeholder="请输入验证码"
                                            iconRender={(visible) => (visible ? <EyeTwoTone/> :
                                                <EyeInvisibleOutlined/>)}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="send">
                                    <Button
                                        type='primary'
                                        disabled={btnDisabled}
                                        style={{height: '5vh', width: '5vw', fontSize: '0.7vw'}}
                                        icon={<SendOutlined/>}
                                        onClick={sendCode}
                                    >
                                        {btnContent}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="loginButton">
                            <Button loading={loading} type='primary' htmlType="submit"
                                    style={{width: "70%", height: '4vh'}}>
                                登陆
                            </Button>
                        </div>
                        <br/>
                        <div className="Footer">
                            <Button onClick={info} type="link">
                                登陆遇到问题?
                            </Button>
                        </div>
                    </Form>
                </Card>
            </div>
            <div className='loginBottom'>
                浙ICP备2023049326号-2
                <a style={{marginLeft: '20px', color: '#287ec0', cursor: 'pointer'}} href={'https://beian.miit.gov.cn/'}>工信部备案查询</a>
            </div>
        </div>
    );
}

export default Login;
