import React, {useEffect, useState} from 'react';
import {Button, Select, Table, Tag} from "antd";
import {getAppointmentPage} from "../../../api";
import {showFail} from "../../../components/functions";
import AppointmentDetailDrawer from "../../../components/appointmentDetailDrawer";
import AssignAppointmentMemberModal from "../../../components/assignAppointmentMemberModal";
import AssignAppointmentMemberDetailModal from "../../../components/assignAppointmentMemberDetailModal";
import styles from "./index.module.css";

const AppointmentManage = () => {
    let isAreaManager = JSON.parse(localStorage.getItem("isAreaManager") as string);
    let sysUser = JSON.parse(localStorage.getItem("sysUser") as string);

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [appointmentId, setAppointmentId] = useState<string | number>("");
    const [appointmentData, setAppointmentData] = useState<any[]>([]);
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    let params: any = {}
    const handleGetAppointmentList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);
        params = searchCondition;
        params.pageSize = pageSize;
        params.pageNum = pageNum;
        console.log('params', params)
        getAppointmentPage(params).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setTotal(Number(res?.data?.total));
                setAppointmentData(res?.data?.records);
            } else {
                showFail(res?.msg)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetAppointmentList(pageNum, pageSize, newSearchCondition);
    }, [pageSize, pageNum])


    const [searchCondition, setSearchCondition] = useState<any>({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);
    const [detailDrawerOpen, setDetailDrawerOpen] = useState<boolean>(false);
    const handleCloseDetailDrawer = () => {
        setDetailDrawerOpen(false)
    }

    const [appointmentRecord, setAppointmentRecord] = useState<any>();
    const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
    const handleAssignModalCancel = () => {
        setAssignModalOpen(false);
    }

    const [assignDetailModalOpen, setAssignDetailModalOpen] = useState<boolean>(false);
    const handleAssignDetailModalCancel = () => {
        setAssignDetailModalOpen(false);
    }

    const [columns, setColumns] = useState<any[]>([
        {
            title: '单位名称',
            dataIndex: 'unitName',
            key: 'unitName',
        },
        {
            title: '预约单状态',
            dataIndex: 'selectState',
            key: 'selectState',
            render: (selectState: any, record: any) => (
                <Tag
                    color={selectState === '已分配' ? 'success' :
                        selectState === '未分配' ? 'processing' : 'error'}>{selectState}</Tag>
            )
        },
        {
            title: '状态说明',
            dataIndex: 'appointmentState',
            key: 'appointmentState',
        },
        {
            title: '发起人',
            dataIndex: 'applicantName',
            key: 'applicantName',
            render: (applicantName: any, record: any) => (<>
                {applicantName} {record?.loginPhoneNumber}
            </>)
        },
        {
            title: '发起时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '人员分配',
            dataIndex: 'assign',
            key: 'assign',
            render: (assign: any, record: any) => (
                <>
                    {record.selectState === "未分配" ? (
                        (record?.deptId === sysUser?.deptId && isAreaManager && record?.appointmentState !== '重派') ?
                            <Button type={'link'} onClick={() => {
                                setAppointmentId(record?.appointmentId)
                                setAssignModalOpen(true);
                            }}>分配</Button> : <div style={{width: 100, textAlign: 'center'}}>待分配</div>
                    ) : (
                        record.selectState === "已分配" ? (
                            <div>
                                <Button type={'link'} onClick={() => {
                                    setAppointmentRecord(record);
                                    setAssignDetailModalOpen(true);
                                }}>分配情况</Button>
                            </div>
                        ) : (
                            <div/>
                        )
                    )}
                </>
            )
        },
        {
            title: '详情',
            dataIndex: 'appointmentId',
            key: 'appointmentId',
            render: ((appointmentId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        setDetailDrawerOpen(true);
                        setAppointmentId(appointmentId);
                    }}>查看详情</Button>
                </>

            ))
        },
    ])

    const handleSelectStateChange = (v: any) => {
        setSearchCondition({...searchCondition, selectState: v})
    }

    const handleTimeTypeChange = (v: any) => {
        setSearchCondition({...searchCondition, typeTime: v})
    }

    const handleAppointmentStateChange = (v: any) => {
        setSearchCondition({...searchCondition, appointmentState: v})
    }

    return (
        <div>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[
                                {value: "未分配", label: "未分配"},
                                {value: "已分配", label: "已分配"},
                                {label: "作废", value: "作废"}
                            ]}
                            placeholder="分配状态" onChange={handleSelectStateChange}
                            value={searchCondition.selectState}
                            allowClear/>

                    {searchCondition.selectState === '已分配' && (
                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[
                                    {value: "大于", label: "未开始"},
                                    {value: "等于", label: "进行中"},
                                    {value: "小于", label: "已结束"},
                                ]}
                                placeholder="任务进行状态" onChange={handleTimeTypeChange}
                                value={searchCondition.typeTime}
                                allowClear/>
                    )}

                    {searchCondition.selectState === '作废' && (
                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                options={[
                                    {value: "撤单", label: "撤单"},
                                    {value: "退回", label: "退回"}
                                ]}
                                placeholder="作废原因" onChange={handleAppointmentStateChange}
                                value={searchCondition.appointmentState}
                                allowClear/>
                    )}


                </div>

                <div className={styles.searchButton}>
                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetAppointmentList(pageNum, pageSize, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition({});
                        setSearchCondition({});
                    }}>重置查询条件
                    </Button>
                </div>

            </div>


            <Table rowKey={'id'} columns={columns} dataSource={appointmentData}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>
            <AppointmentDetailDrawer appointmentId={appointmentId} open={detailDrawerOpen}
                                     onCancel={handleCloseDetailDrawer}/>
            <AssignAppointmentMemberModal open={assignModalOpen} onCancel={handleAssignModalCancel}
                                          appointmentId={appointmentId} handleGetAppointment={async () => {
                handleGetAppointmentList(pageNum, pageSize, newSearchCondition)
            }}/>
            <AssignAppointmentMemberDetailModal open={assignDetailModalOpen} onCancel={handleAssignDetailModalCancel}
                                                record={appointmentRecord}/>
        </div>
    );
};

export default AppointmentManage;